.MuiTableRow-root {
  background-color: #F7F8FA;
}

.MuiTableRow-hover:hover{
  background-color: #FFFFFF;
  /* background-color: #F7787821 !important; */

}

.custom-selected {
  background-color: #FFFFFF!important;
}


